<template>
  <span>
      <a href="#" class="ms-participant" @click='show = true'> 
      <span v-if="creator">
        <i v-if="getStatus == 0" class="fal fa-envelope"></i>
        <i v-if="getStatus == 1" class="fal fa-envelope-open"></i>
        <i v-if="getStatus == 2" class="fal fa-ban"></i>
        <i v-if="getStatus == 3" class="fal fa-comment"></i>
        <i v-if="getStatus == 10" class="fal fa-exclamation-circle red"></i>
      </span>
      {{info.email}}<span v-if="!islast">; </span></a>

      <b-sidebar
      id="message-securelink"
      v-model="show"
      width="80%"
      right
      no-slide
      shadow
      backdrop
    >      
      <div class="px-3 py-2">

      <UserInfo
        class="mt-4"
        image="/img/default-avatar.png"
        :primary="info.email"
      ></UserInfo>

          <hr class="pb-0 mb-0 mt-2" />

      <dl class="mt-2 clearfix">
           <dt>{{ $t("STATUS") }}</dt>
          <dd>
            <i v-if="getStatus == 0" class="fal fa-envelope"></i>
            <i v-if="getStatus == 1" class="fal fa-envelope-open"></i>
            <i v-if="getStatus == 2" class="fal fa-ban"></i>
            <i v-if="getStatus == 3" class="fal fa-comment"></i>
            <i v-if="getStatus == 10" class="fal fa-exclamation-circle red"></i> {{ showStatus }}
          </dd>
          <dt v-if="item.viewed">{{ $t('VIEWED') }}</dt>
          <dd v-if="item.viewed">{{ $luxonDateTime.fromISO(item.viewed_date).toLocaleString($luxonDateTime.DATETIME_MED) }}</dd>
          <dt>{{ $t('AUTH_METHODS.VERIFY') }}</dt>
          <dd><b-img class="icon" fluid :src="authenticationImage"></b-img> {{showMethod}}</dd>
          <dt v-if="item.recalled">{{ $t('RECALLED') }}</dt>
          <dd v-if="item.recalled">{{ $luxonDateTime.fromISO(item.recalled_date).toLocaleString($luxonDateTime.DATETIME_MED) }}</dd>
          <dt v-if="checkResponse">{{ $t('RESPONSED') }}</dt>
          <dd v-if="checkResponse">{{ response_status }}</dd>  
      </dl>

      <RespondedMessage class="mt-2" v-if="showResponse" :message_uuid="info.response_message_uuid"></RespondedMessage>
    
     <hr class="pb-0 mb-0 mt-2" />

      <b-button v-if="info.response_message_uuid != '' && showResponse == false"
        variant="primary"
        size="sm"
        class="btn-fill mt-2"
        @click="viewResponse"
        >{{ $t("SHOW_RESPONSE") }}
      </b-button>

      <b-button v-if="getStatus == 0"
        variant="primary"
        size="sm"
        class="btn-fill mt-2"
        @click="recall"
        >{{ $t("RECALL") }}
      </b-button>
  
      <b-button
        v-if="getStatus == 10"
        variant="primary"
            size="sm"
        class="btn-fill mt-2"
        @click="resend"
        >{{ $t("RESEND") }}</b-button
      >
      </div>

    </b-sidebar>
  </span>
</template>
<script>
export default {
  components: { 
    RespondedMessage: () => import("@/components/Read/RespondedMessage"),
    UserInfo: () => import("@/components/Layout/UserInfo")
  },
  props: ["response","item","islast","creator","status"],
  data() {
    return {
      show: false,
      info: this.item,
      showResponse: false,
      viewed: false,
      viewed_date: ''
    };
  },
  methods: {
    viewResponse: function() {
      this.showResponse = true;
    },
    onShow: function() {
      this.$root.$emit('bv::hide::popover')
    },
    onClose: function() {
      this.showDialog = false;
    },
    recall: function() {
      let self = this;
      this.loading = true;
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
           self.$http
              .post(self.user.hostname + "/message/link-recall", {
                  link_id: self.info.id
                })
              .then(
                function(response) {
                  self.info.recalled = 1;
                  self.info.recalled_date = response.data.recalled_date;
                  self.$noty.info(self.$t("UPDATED"));
                }
              )
              .catch(function(error) {
                self.loading = false;
                if(error.toString() == "Error: fail_4_4") return;
                if (error.response != undefined) {
                  self.$noty.error(self.$t(error.response.data));
                }
              });
        }
      })
      .catch(function(){
      });      
    },
    resend: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/link-resend",
              {
                link_id: self.info.id
              }
            )
            .then(function() {
              self.loading = false;
              if(self.info.sent != undefined)
              {
                self.info.sent = 1;
              }
              self.$noty.info(self.$t("UPDATED"));
            })
            .catch(function(error) {
              self.loading = false;
              if(error.toString() == "Error: fail_4_4") return;
              if (error.response != undefined) {
                self.$noty.error(self.$t(error.response.data));
              }
            });
        }
      })
      .catch(function(){
      }); 
    }
  },
  computed: {  
    authenticationImage() {
      return "/img/auth/" + this.info.authentication_method + ".png";
    },
    checkResponse() {
      if(this.response != undefined)
      {
        return this.response;
      }else{
        if(this.info.response != undefined)
        {
          return this.info.response;
        }else{
          return false;
        }
      }
    },    
    response_status() {
      return this.responsed ? this.$t('YES') : this.$t('NO')
    },  
    responsed () {
      return this.info.response_message_uuid != "" ? true : false;
    }, 
    showMethod() {
      return this.$t("AUTH_METHODS." + this.info.authentication_method);      
    },    
    getStatus() {
      if(this.info.response_message_uuid != "")
      {
        return 3;
      }
      if(this.info.recalled == 1)
      {
        return 2;
      }
      if(this.info.viewed == 1)
      {
        return 1;
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return 10;
        }
      }
      return 0;
    },
    showStatus() {
      if(this.info.response_message_uuid != "")
      {
        return this.$t("RESPONSED");
      }
      if(this.info.recalled == 1)
      {
        return this.$t("RECALLED");
      }
      if(this.info.viewed == 1)
      {
        return this.$t("VIEWED");
      }
      if(this.info.sent != undefined)
      {
        if (this.info.sent == 0) {
          return this.$t("NOT_SENT");
        }
      }
      return this.$t("NOT_VIEWED");
    }
  }
};
</script>
<style>
</style>